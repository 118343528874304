import { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Typography, Box } from '@mui/material'; // Assuming you're using Material-UI

const ENDPOINT_VALIDATE_USER_LINKS = process.env.REACT_APP_AI_API_URL + '/api/v1/validate-user-links/';

const UserOrganizationStatus = ({ token }) => {
    const [status, setStatus] = useState({ error: null, data: null });
    const [alertMessage, setAlertMessage] = useState(null); // State to track alert message
    const [showAlert, setShowAlert] = useState(false); // State to control alert rendering
    const [showBox, setShowBox] = useState(true); // State to control Box visibility

    useEffect(() => {
        const fetchUserOrganization = async () => {
            try {
                const { data } = await axios.get(ENDPOINT_VALIDATE_USER_LINKS, {
                    headers: { 'x-access-token': token }
                });

                if (data.no_longer_organizations.length > 0) {
                    let organizations = data.no_longer_organizations.map((element) => element.organization);
                    let no_longer_organizations_str = organizations.join(", ");
                    setAlertMessage(`You no longer belong to the following organizations: ${no_longer_organizations_str}`);
                    setShowAlert(true); 
                    setShowBox(true);  
                }

                setStatus({ error: null, data: data });
            } catch (error) {
                setStatus({ error: 'Error validating user status.', data: null });
                setShowBox(true);
            }
        };

        fetchUserOrganization();
    }, [token]);

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
                setShowBox(false); // Hide Box after 5 seconds
            }, 7000); // Box disappears after 5 seconds

            return () => clearTimeout(timer);
        }
    }, [showAlert]);

    return (
        <>
            {showAlert && (
                <Alert 
                    style={{
                        position: 'fixed',
                        top: '20px',
                        right: '20px',
                        zIndex: 10000,
                        width: '450px'  // Adjust width if needed
                    }} 
                    severity="warning" 
                    onClose={() => setShowAlert(false)}
                >
                    {alertMessage}
                </Alert>
            )}
            
            {showBox && (
                <Box>
                    {status.error ? (
                        <Alert severity="error">{status.error}</Alert>
                    ) : (
                        status.data && (
                            <Typography variant="body1">
                                User status validated successfully.
                            </Typography>
                        )
                    )}
                </Box>
            )}
        </>
    );
};

export default UserOrganizationStatus;
