export default async function authenticateReferrer(credentials, token){
    let url = `${process.env.REACT_APP_AI_API_URL}/api/v1/refiere-uno-mas/auth`
    console.log(credentials)
    console.log(token)
    let response = undefined;
    let data = undefined;
    let error = undefined;

    try{
        response = await fetch(url, {
            method: "POST", 
            headers: {
                'x-access-token': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
    
        data = await response.json()

        if (!data["success"]){
            error = data["message"]
            data = undefined
        }

    }catch(e){
        error = "Ocurrio un error" 
    }

    return {response, data, error}
}