import { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import UnibeAPIClient from "../services/organizations/unibeAPIClient";

const LinkUnibeUserForm = ({ onCloseModal, auth_token, selectedRole }) => {
  const classes = useStyles();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [identification, setIdentification] = useState("")
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [identificatonErrorMessage, setIdentificatonErrorMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageSucceed, setIsMessageSucceed] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const prospectRegex = /^[a-zA-Z]+$/;
  const studentRegex = /^\d{2}-\d{4}$/;
  let isValid = false;

  const handleCode = (event) => {
    const { value } = event.target;
    setCodeErrorMessage("");

    // if (selectedRole === "Estudiante"){
    //   isValid = studentRegex.test(value)
    //   setCodeErrorMessage(isValid ? '' : 'Codigo de estudiante inválido');

    // }else if(selectedRole === "Prospecto"){
    //   isValid = prospectRegex.test(value)
    //   setCodeErrorMessage(isValid ? '' : 'Codigo de prospecto inválido')
    // }

    setCode(value);
  };

  const handleIdentification = (event) => {
    const {value} = event.target;
    setIdentificatonErrorMessage("")
    setIdentification(value)
  }



  const handlePassword = (event) => {
    const { value } = event.target;
    setPassword(value);
  };

  const handleSubmit = async (e) => {
    setErrorMessage("");
    setIsLoading(true);
    e.preventDefault();
    let payload = {
      "code": code,
      "linkedType": selectedRole,
      "identification": identification
    };

    const APIClient = new UnibeAPIClient(auth_token);
    let response = await APIClient.authenticate(payload);
    let { data, error } = response;


    if (!data){
      setIsLoading(false);
      setErrorMessage(error.response.data.message);
    }else{
      setIsLoading(false);
      setIsLoginSuccess(true)
    }

  };

  return (
    <>
      {isLoginSuccess ? (
        <>
          <p>Usuario autenticado con exito</p>
          <Button
            style={{ color: "#FFF", textTransform: "none" }}
            onClick={onCloseModal}
          >
            Cerrar
          </Button>
        </>
      ) : (
        <>
          <p>{`Autenticarme como ${selectedRole}`}</p>
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isLoading && <CircularProgress color="success" />}
            </div>
            {isMessageSucceed && (
              <Typography
                style={{
                  color: "#269202",
                  fontFamily: "Figtree",
                  marginBottom: 10,
                }}
              >
                Usuario autenticado con exito
              </Typography>
            )}
            <InputLabel>Código de usuario</InputLabel>

            <TextField
              type="text"
              fullWidth
              margin="normal"
              value={code}
              onChange={handleCode}
              error={!!codeErrorMessage}
              helperText={codeErrorMessage}
              required
              sx={{ "& .MuiInputBase-input": { color: "black" } }}
            />
          
            <InputLabel>Identificacion</InputLabel>          
            <TextField
              type="text"
              fullWidth
              margin="normal"
              value={identification}
              onChange={handleIdentification}
              error={!!identificatonErrorMessage}
              helperText={identificatonErrorMessage}
              required
              sx={{ "& .MuiInputBase-input": { color: "black" } }}
            />

            <Box mt={2}>
              <Button type="submit" fullWidth className={classes.buttonLink}>
                Autenticar usuario
              </Button>

              {errorMessage && (
                <Typography
                  style={{
                    color: "#ff8661",
                    textAlign: "center",
                    padding: 5,
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Box>
          </form>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  buttonLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "16px 48px",
    borderRadius: "8px",
    backgroundColor: "#8066ec",
    textTransform: "none",
    flexGrow: 0,
    fontFamily: "Figtree",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#6851c8",
    },
  },
});

export default LinkUnibeUserForm;
