import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import authenticateReferrer from "../services/organizations/refiereUnoMas";

const LinkReferrerForm = ({ onCloseModal, auth_token, selectedRole }) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageSucceed, setIsMessageSucceed] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setErrorMessage("");
    setIsLoading(true);
    e.preventDefault();
    const { data, error } = await authenticateReferrer(formData, auth_token);

    if(error){
      setIsLoading(false);
      setErrorMessage(error);
      return
    }

    setIsLoading(false);
    setIsLoginSuccess(true);

    setFormData({
      username: "",
      password: "",
    });
  };

  return (
    <>
      {isLoginSuccess ? (
        <>
          <p>Usuario autenticado con exito</p>
          <Button
            style={{ color: "#FFF", textTransform: "none" }}
            onClick={onCloseModal}
          >
            Cerrar
          </Button>
        </>
      ) : (
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "400px", margin: "auto" }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isLoading && <CircularProgress color="success" />}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label
              htmlFor="username"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Username:
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <label
              htmlFor="password"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Password:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
          </div>

          <Box mt={2}>
            <Button type="submit" fullWidth className={classes.buttonLink}>
              Autenticar usuario
            </Button>

            {errorMessage && (
              <Typography
                style={{
                  color: "#ff8661",
                  textAlign: "center",
                  padding: 5,
                }}
              >
                {errorMessage}
              </Typography>
            )}
          </Box>
        </form>
      )}
    </>
  );
};

const useStyles = makeStyles({
  buttonLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "16px 48px",
    borderRadius: "8px",
    backgroundColor: "#8066ec",
    textTransform: "none",
    flexGrow: 0,
    fontFamily: "Figtree",
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#6851c8",
    },
  },
});

export default LinkReferrerForm;
