import axios from 'axios';

export default async function referEvent(name, CellPhone, IssuedId) {

    var content = {
        "title": "Referimiento AFP - Lisa",
        "type": "referimientos",
        "field_nombre_del_cliente": {
            "und": [
                { "value": name }
            ]
        },
        "field_no_de_celular": {
            "und": [
                { "value": CellPhone }
            ]
        },
        "field_no_de_c_dula": {
            "und": [
                { "value": IssuedId }
            ]
        },
        "field_estado_laboral": {
            "und": "702"
        },
        "field_relaci_n_con_el_referidor": {
            "und": "703"
        },
        "field_horario_de_contacto": {
            "und": { "value": "634" }
        },
        "field_tipo_de_referimiento": {
            "und": { "value": "666" }
        }
    }
    let config = {
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'API-KEY': 'g9T837TjuZ2bBp_9idiCiEiFA324Mea-tkyiKpnj8aw',
            'TOKEN': 'uUvcS_GDiIprU-YF8Zf34YYV4r3R5behZApwtHVSlIY'
        }
    }
    await axios.post('https://refiere.crecerlab.com/referir/node', content, config)
        .then(function (response) {
            return true;

        })
        .catch(function (error) {
            return false;
        });

}